<template>
  <div>
    <div class="compont_p">
      订单详情
      <div class="btn flex_c_c" @click="$router.go(-1)">返回上级</div>
    </div>
    <div class="info_box mt_10">
      <div class="c3 flex_row_aic">
        <p class="mr_40"><span class="c6">订单编号：</span>{{ detail.orderno }}</p>
        <p class="mr_40"><span class="c6">订单时间：</span>{{ detail.order_time_str }}</p>
        <p class="mr_40"><span class="c6">状态：</span>{{ detail.status_str }}</p>
        <!-- <el-button class="custombtn" type="primary">付款</el-button> -->
      </div>
      <p class="c3"><span class="c6">销售单位：</span>{{ detail.dwmc }}</p>
      <p class="c3"><span class="c6">配送信息：</span>{{ detail.address && detail.address.deliverymethod }}</p>
      <p class="c3"><span class="c6">支付方式：</span>{{ detail.zffs }}</p>
      <p class="c3">
        <span class="c6">收货信息：</span>{{ detail.address && detail.address.name }}&nbsp;&nbsp; {{ detail.address && detail.address.phone }}&nbsp;&nbsp;&nbsp;
        {{ detail.address && detail.address.address_str }}
      </p>
    </div>
    <el-table :data="detail.goods" class="mt_10">
      <el-table-column prop="date" label="商品名称/规格" align="center" width="150">
        <template slot-scope="{ row }">
          <p>{{ row.title }}</p>
          <p class="c6">{{ row.specification }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="validity_date" label="有效期" align="center" />
      <el-table-column prop="manufacturer" label="厂家" align="center" width="200" />
      <el-table-column prop="price" label="购买价格" align="center" />
      <el-table-column prop="number" label="商品数量" align="center" />
      <el-table-column prop="ck_number" label="出库数量" align="center" />
      <el-table-column prop="total_price" label="购买合计" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button v-if="row.splx == 1 || row.splx == 2" class="custombtn" type="primary" @click="handleBuy(row)">再次购买</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="amount">
      <div>
        <p>
          商品总额：<span>{{ detail.total_price }}</span>
        </p>
        <p>
          商品运费：<span>{{ detail.freight }}</span>
        </p>
        <p>
          订单总额：<span>{{ (Number(detail.total_price) + Number(detail.freight)).toFixed(2) }}</span>
        </p>
        <p>
          优惠金额：<span class="color_theme">{{ detail.coupon_money }}</span>
        </p>
        <p>
          实付金额：<span>{{ detail.goods_total_price }}</span>
        </p>
      </div>
    </div>
    <div class="note">
      <p>
        用户备注：<span>{{ detail.bz }}</span>
      </p>
      <p>
        优惠信息：<span>{{ detail.yhxx }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: {},
    };
  },
  created() {
    let id = this.$route.query.id;
    this.$api("account.getOrderDel", { id }).then((res) => {
      this.detail = res.data;
    });
  },
  methods: {
    handleBuy(item) {
      let p = {
        id: item.id,
        number: item.number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("已加入购物车");
        this.$store.dispatch("getBadge");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 100px;
    top: 0;
    width: 76px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    background: @themeColor;
  }
}
.info_box {
  line-height: 3;
  .mr_40 {
    margin-right: 40px;
  }
  .custombtn {
    font-size: 14px;
    width: 76px;
  }
}
.amount {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 20px;
  font-weight: bold;
  line-height: 2.2;
  color: #333;
  span {
    color: #f93232;
  }
  .color_theme {
    color: @themeColor;
  }
}
.note {
  font-weight: bold;
  line-height: 2.2;
  color: #333;
}
</style>
